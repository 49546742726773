import React from 'react'
import { IconButton, Stack, colors } from '@mui/material'
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../utils/AuthContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
function EmployeeMine(props) {
  const { loginUser } = useContext(AuthContext)
  return (
    <div>
    
      <Stack sx={{ width: "100%", height: "100vh",overflow:"hidden"}}  alignItems="center">
            <Stack sx={{ width: "100%", height: "100vh", backgroundColor: colors.blueGrey[50],alignItems:'center'}}>
                <Stack component="nav" direction="row-reverse" p={1} mb={1} sx={{ borderBottom: "1px solid #ddd" }}>
                    <Link to={'/'}><IconButton size='large'><HomeTwoToneIcon /></IconButton></Link>
                </Stack>
                <Stack component="main" m={1} sx={{overflowY:"auto",overflowX:"hidden" }} pb={1}>
                    {props.children}
                   
                </Stack>
            </Stack>
        </Stack>
    </div>
  )
}

export default EmployeeMine
