import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './update.css'
import PanelSidebar from '../PanelSlidebar';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../utils/AuthContext';
import { useContext } from 'react';
function GalleryG() {
  const [galleryDelete, setgalleryDelete] = useState([]); 
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext)
  useEffect(() => {
    fetch(`http://localhost:3001/api/v1/gallery1`) 
      .then((res) => res.json())
      .then((data) => setgalleryDelete(data.data)) 
      .catch((err) => console.log(err));
  }, [id]);

  const deleteItem = (id) => {
    fetch(`http://localhost:3001/api/v1/gallery1/${id}`, { 
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        setgalleryDelete(galleryDelete.filter(galleryPage => galleryPage._id !== id)); 
      })
      .catch((err) => console.log(err));
      
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div  className='myslider-panel' >
        <h1>gallery حذف کردن اطلاعات </h1>
        <PanelSidebar/>
      {galleryDelete?.map(galleryPage => ( // Changed from sliderData to galleryDelete
        <div className='slide'  key={galleryPage._id}> 
          <h3>{galleryPage.title}</h3>
          <img src={`http://localhost:3001${galleryPage.image}`} alt={galleryPage.title} /> 
          <p>{galleryPage.description}</p> /
          <button onClick={() => deleteItem(galleryPage._id)}>حذف</button> 
        </div>
      ))}
    </div>
  );
}

export default GalleryG;
