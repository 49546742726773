import React, { useContext , useState } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupIcon from '@mui/icons-material/Group';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import BurstModeRoundedIcon from '@mui/icons-material/BurstModeRounded';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import Typography from '@mui/material/Typography';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
import { ListItemButton,Chip,Stack,Button,Avatar,Collapse,IconButton} from '@mui/material';
import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';
import LocalPoliceTwoToneIcon from '@mui/icons-material/LocalPoliceTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import QueueTwoToneIcon from '@mui/icons-material/QueueTwoTone';
import Face2TwoToneIcon from '@mui/icons-material/Face2TwoTone';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import Diversity1TwoToneIcon from '@mui/icons-material/Diversity1TwoTone';
import { Person2Rounded } from '@mui/icons-material';
import { ExpandLess ,ExpandMore } from '@mui/icons-material';
import { AuthContext } from '../../utils/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Navigate } from 'react-router-dom';


const drawerWidth = 240;

const Employees = ({ children, location }) => {

  const theme = useTheme();
  const [open, setOpen] = React.useState({users:false,update:false,wage:false,inbox:false});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openMenu, setOpenMenu] = useState(false);
  const { loginUser, setLoginUser } = useContext(AuthContext)
  const handleDrawerOpen = () => {
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpenMenu(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ width: '100%', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#818589' }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="end"
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap>
            داشبورد کارمند
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="right"
        open={isMobile ? openMenu : true}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
          ...(isMobile && {
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }),
        }}
      >
        <Toolbar />
        <List>
          <Stack justifyContent="center" alignItems="center" p={2} gap={2}>
            <Typography component="p">سلام {loginUser?.fullName ? loginUser.fullName : loginUser?.role}</Typography>
            <Chip
              icon={<LocalPoliceTwoToneIcon />}
              label={loginUser?.role}
              color="warning"
              variant="filled"
              size="medium"
              sx={{ '& .MuiChip-label': { paddingRight: "0" }, '& .MuiChip-icon': { marginRight: "6px" } }}
            />
          </Stack>
          <ListItemButton component={Link} to="/employee" selected={location?.pathname === "/employee"}>
            <ListItemIcon>
              <SpeedTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="داشبورد" />
          </ListItemButton>
        
          <ListItemButton onClick={()=>setOpen({...open,users:!open.users})}>
                    <ListItemIcon>
                        <Person2Rounded />
                    </ListItemIcon>
                    <ListItemText primary="کاربران" />
                    {open?.users ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.users} timeout="auto" unmountOnExit >
                    <List component="div" disablePadding >
                        <ListItemButton component={Link} >
                            <ListItemIcon>
                                <BadgeTwoToneIcon />
                            </ListItemIcon>
                            <ListItemText primary="پروفایل" />
                        </ListItemButton>
                      
                        <ListItemButton component={Link} to="/employee/dayoff" >
                            <ListItemIcon>
                                <PeopleAltIcon/>
                            </ListItemIcon>
                            <ListItemText primary=" درخواست های مرخصی"/>
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItemButton onClick={()=>setOpen({...open,wage:!open.wage})}>
                    <ListItemIcon>
                        <NewspaperRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="امور مالی" />
                    {open?.wage ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.wage} timeout="auto" unmountOnExit >
                    <List component="div" disablePadding >
                       
                    
          <ListItemButton component={Link} to="/employee/wage">
            <ListItemIcon>
              <ReceiptLongIcon/>
            </ListItemIcon>
            <ListItemText primary='ارسال فیش حقوقی' />
          </ListItemButton>
                    </List>
                </Collapse>

                <ListItemButton onClick={()=>setOpen({...open,inbox:!open.inbox})}>
                    <ListItemIcon>
                        <InboxIcon/>
                    </ListItemIcon>
                    <ListItemText primary="صندوق ورودی" />
                    {open?.inbox ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.inbox} timeout="auto" unmountOnExit >
                    <List component="div" disablePadding >
                       
                    
          <ListItemButton component={Link} to="/employee/sendemail">
            <ListItemIcon>
              < ForwardToInboxRoundedIcon/>
            </ListItemIcon>
            <ListItemText primary='ارسال ایمیل' />
          </ListItemButton>
                    </List>
                </Collapse>

        </List>

          <Button variant="outlined" onClick={() => {
                    setLoginUser(null)
                    localStorage.removeItem('loginUser')
                }}>خروج</Button>
      </Drawer>
      <main>
        <Toolbar />
        {children}
      </main>
    </div>
  );
};

export default Employees;