import React, { useState, useEffect ,useContext } from 'react';
import { Box, Button, Stack, Typography, TextField, Snackbar, Alert, Select, MenuItem } from '@mui/material';
import PanelSidebar from '../PanelSlidebar';
import { AuthContext } from '../../../utils/AuthContext';
import { Navigate } from 'react-router-dom';
export default function UpdateUser() {
  const { loginUser } = useContext(AuthContext)
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    severity: 'error'
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/v1/user', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        const data = await response.json();
        console.log('Fetched users:', data.users);
        setUsers(data.users || []);
      } catch (error) {
        console.error('Error fetching users:', error);
        setSnack({ open: true, message: 'خطا در بارگیری کاربران', severity: 'error' });
      }
    };
    fetchUsers();
  }, []);

  const handleEdit = (user) => {
    console.log('Editing user:', user);
    setEditUser(user);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditUser({ ...editUser, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEditUser({ ...editUser, profilePic: file });
  };

  const handleUpdate = async () => {
    if (!editUser) return;

    const formData = new FormData();
    formData.append('fullName', editUser.fullName);
    formData.append('email', editUser.email);
    formData.append('phone', editUser.phone);
    formData.append('address', editUser.address);
    formData.append('role', editUser.role);
    if (editUser.profilePic) {
      formData.append('file', editUser.profilePic);
    }

    try {
      const response = await fetch(`http://localhost:3001/api/v1/user/${editUser._id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData
      });
      if (response.ok) {
        const updatedUser = await response.json();
        console.log('Updated user:', updatedUser.data.data);
        setSnack({ open: true, message: 'کاربر با موفقیت به‌روزرسانی شد', severity: 'success' });
        setUsers(users.map(user => user._id === editUser._id ? updatedUser.data : user));
        setEditUser(null);
      } else {
        throw new Error('Error updating user');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setSnack({ open: true, message: 'خطا در به‌روزرسانی کاربر', severity: 'error' });
    }
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div>
      <PanelSidebar/>
    <Stack alignItems="center" justifyContent="center" height="100vh">
      <Box width="90%" maxWidth="600px" p={3} bgcolor="background.paper" borderRadius={1}>
        <Typography component="h1" variant="h5" align="center" gutterBottom>مدیریت کاربران</Typography>
        {users.map(user => (
          <Box key={user._id} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography>{user.fullName} ({user.email})</Typography>
            <Button variant="contained" color="primary" onClick={() => handleEdit(user)}>ویرایش</Button>
          </Box>
        ))}
      </Box>
      {editUser && (
        <Box width="90%" maxWidth="600px" p={3} bgcolor="background.paper" borderRadius={1} mt={4}>
          <Typography component="h2" variant="h6" align="center" gutterBottom>ویرایش کاربر</Typography>
          <TextField name="fullName" label="نام و نام خانوادگی" variant="outlined" value={editUser.fullName} onChange={handleChange} fullWidth margin="normal" />
          <TextField name="email" label="ایمیل" variant="outlined" value={editUser.email} onChange={handleChange} fullWidth margin="normal" />
          <TextField name="phone" label="موبایل" variant="outlined" value={editUser.phone} onChange={handleChange} fullWidth margin="normal" />
          <TextField name="address" label="آدرس" variant="outlined" value={editUser.address} onChange={handleChange} fullWidth margin="normal" />
          <Select name="role" value={editUser.role} onChange={handleChange} fullWidth margin="normal">
            <MenuItem value="admin">ادمین</MenuItem>
            <MenuItem value="superAdmin">سوپر ادمین</MenuItem>
            <MenuItem value="employee">کارمند</MenuItem>
          </Select>
          <input type="file" onChange={handleFileChange} style={{ marginTop: '16px', marginBottom: '16px' }} />
          <Button variant="contained" color="primary" onClick={handleUpdate} fullWidth>به‌روزرسانی</Button>
        </Box>
      )}
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert onClose={() => setSnack({ ...snack, open: false })} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
    </Stack>
   </div>
  );
}
