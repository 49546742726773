// LanguageSwitcher.js
import React,{useEffect} from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { LanguageContext } from '../utils/LanguageContext';
import { useContext } from 'react';
import './btn.css'
const TranslateButton = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [textDirection, setTextDirection] = useState('rtl');
  const { language, changemyLanguage } = useContext(LanguageContext);

  const handleLanguageChange = (newLanguage) => {
      changeLanguage(newLanguage);
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language);

  }, [i18n.language]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    // document.documentElement.setAttribute('dir', language === 'fa' ? 'rtl' : 'ltr');
    // document.documentElement.style.textAlign = language === 'fa' ? 'right' : 'left';
  };
  useEffect(() => {
    //  document.documentElement.setAttribute('dir', 'rtl');
    // document.documentElement.style.textAlign = 'right';
    if (i18n.language === 'en') {
      setTextDirection('ltr');
    } else {
      setTextDirection('rtl');
    }
  
  }, []);

  return (
    <div className='option'  style={{ direction: textDirection }}  >
      {/* <InputLabel sx={{ display: 'flex', flexDirection: 'row' }} id="language-label" htmlFor="language">
        <LanguageIcon />
      </InputLabel>
      <Select
        labelId="language-label"
        id="language"
        value={selectedLanguage}
        onChange={(e) => {
          setSelectedLanguage(e.target.value);
          changeLanguage(e.target.value);
        }}
        label="Language"
      >
        <MenuItem value="fa">فارسی</MenuItem>
        <MenuItem value="en">English</MenuItem>
      // </Select> */}
     <button className="icon-btn" onClick={() => changeLanguage(i18n.language === 'fa' ? 'en' : 'fa')}> <LanguageIcon/></button>
      
         {/* <select value={language} onChange={(e) => handleLanguageChange(e.target.value)}>
                   <option value="English">English</option>
                   <option value="Persian">Persian</option>
               </select> */}
    </div>
  );
};

export default TranslateButton;
