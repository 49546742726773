import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Typography from "@mui/material/Typography";
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import EmailIcon from "@mui/icons-material/Email";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import {
  ListItemButton,
  Chip,
  Stack,
  Button,
  Avatar,
  Collapse,
  IconButton
} from "@mui/material";
import LocalMallTwoToneIcon from "@mui/icons-material/LocalMallTwoTone";
import LocalPoliceTwoToneIcon from "@mui/icons-material/LocalPoliceTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import BallotTwoToneIcon from "@mui/icons-material/BallotTwoTone";
import QueueTwoToneIcon from "@mui/icons-material/QueueTwoTone";
import Face2TwoToneIcon from "@mui/icons-material/Face2TwoTone";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import GroupAddTwoToneIcon from "@mui/icons-material/GroupAddTwoTone";
import Diversity1TwoToneIcon from "@mui/icons-material/Diversity1TwoTone";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupIcon from "@mui/icons-material/Group";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import { PeopleAlt, Person2Rounded } from "@mui/icons-material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { AuthContext } from "../../utils/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BurstModeRoundedIcon from "@mui/icons-material/BurstModeRounded";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import RecentActorsRoundedIcon from "@mui/icons-material/RecentActorsRounded";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const drawerWidth = 240;

const PanelSidebar = ({ children, location }) => {
  const [mode, setMode] = useState('light');
  const theme = useTheme();
  const darkTheme = createTheme({
    palette: {
      mode: mode,
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState({
    users: false,
    update: false,
    wage: false,
    inbox: false,
    externalLetter: false,
    internalLetter: false,
    transcript: false
  });
  const { loginUser, setLoginUser } = useContext(AuthContext);
  const [data, setDate] = useState();
  const handleDrawerOpen = () => {
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpenMenu(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: mode === 'light' ? "#2C3E50" : "#555555"
          }}
        >
          <Toolbar>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="end"
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap>
              داشبورد ادمین
            </Typography>
            <IconButton
              sx={{ ml: 'auto' }}
              onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
              color="inherit"
            >
              {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          anchor="right"
          open={isMobile ? openMenu : true}
          onClose={handleDrawerClose}
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: mode === 'light' ? "#ffffff" : "#424242",
              color: mode === 'light' ? "#000000" : "#ffffff"
            },
            ...(isMobile && {
              "& .MuiBackdrop-root": {
                backgroundColor: "#34495E",
                
              }
            })
          }}
        >
          <Toolbar />
          <List>
            <Stack justifyContent="center" alignItems="center" p={2} gap={2}>
              <Typography component="p">
                خوش آمدید
                {loginUser?.fullName ? loginUser.fullName : loginUser?.role}
              </Typography>
              <Chip
                icon={<SupervisedUserCircleIcon />}
                label={loginUser?.profile}
                color="warning"
                variant="filled"
                size="medium"
                sx={{
                  "& .MuiChip-label": { paddingRight: "0" },
                  "& .MuiChip-icon": { marginRight: "6px" }
                }}
              />
            
            </Stack>
            <ListItemButton
              component={Link}
              to="/panel"
              selected={location?.pathname === "/panel"}
            >
              <ListItemIcon>
                <SpeedTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="داشبورد" />
            </ListItemButton>

            <ListItemButton
              onClick={() => setOpen({ ...open, users: !open.users })}
            >
              <ListItemIcon>
                <Person2Rounded />
              </ListItemIcon>
              <ListItemText primary="کاربران" />
              {open?.users ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.users} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/panel/register">
                  <ListItemIcon>
                    <BadgeTwoToneIcon />
                  </ListItemIcon>

                  <ListItemText primary="کاربر جدید" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/users">
                  <ListItemIcon>
                    <RecentActorsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="همه کاربران" />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton
              onClick={() => setOpen({ ...open, update: !open.update })}
            >
              <ListItemIcon>
                <SystemUpdateIcon />
              </ListItemIcon>
              <ListItemText primary="بروز رسانی سایت" />
              {open?.update ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.update} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/panel/slider">
                  <ListItemIcon>
                    <BurstModeRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary=" اسلایدر" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/gallery">
                  <ListItemIcon>
                    <CollectionsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="گالری" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/cart">
                  <ListItemIcon>
                    <CollectionsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary=" کارت" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/gallerypage">
                  <ListItemIcon>
                    <CollectionsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary=" صفحه گالری" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/news">
                  <ListItemIcon>
                    <NewspaperRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="اخبار" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton
              onClick={() => setOpen({ ...open, inbox: !open.inbox })}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="اینباکس" />
              {open?.inbox ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.inbox} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/panel/internal-letter"
                  sx={{ pr: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="نامه داخلی" />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/panel/external-letter"
                  sx={{ pr: 4 }}
                >
                  <ListItemIcon>
                    <ForwardToInboxRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="نامه خارجی" />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={() => setOpen({ ...open, wage: !open.wage })}>
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText primary="دستمزد" />
              {open?.wage ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.wage} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/panel/wage-letter">
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="نامه دستمزد" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/transcript">
                  <ListItemIcon>
                    <ReceiptLongIcon />
                  </ListItemIcon>
                  <ListItemText primary="رونوشت ها" />
                </ListItemButton>
              </List>
            </Collapse>
          </List>
          <Button
            variant="outlined"
            onClick={() => {
              setLoginUser(null);
              localStorage.removeItem("loginUser");
              navigate("/login");
            }}
          >
            خروج
          </Button>
        </Drawer>
        <main>
          <Toolbar />
          {children}
        </main>
      </div>
    </ThemeProvider>
  );
};

export default PanelSidebar;
