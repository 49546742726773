
import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, TextField
} from '@mui/material'; // اطمینان حاصل کنید که TextField را اینجا وارد کنید
import PanelSidebar from './PanelSlidebar';
import { AuthContext } from '../../utils/AuthContext';
import './panel.css';
import { Navigate } from 'react-router-dom';

export default function Users() {
  const { loginUser } = useContext(AuthContext);
  const [userRows, setUserRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [updateFields, setUpdateFields] = useState({
    fullName: '',
    phone: '',
    email: '',
    role: '',
    profilePic: null
  });
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    severity: 'error'
  });
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (loginUser?.token) {
      fetch('http://localhost:3001/api/v1/user', {
        method: "GET",
        headers: {
          "Content-type": "Application/json",
          "Authorization": `Bearer ${loginUser?.token}`
        }
      })
        .then(res => res.json())
        .then(data => {
          if (data.status === 200) {
            setUserRows(data.data);
          } else {
            console.error('Failed to fetch users:', data.message);
          }
        })
        .catch(err => console.log(err));
    }
  }, [loginUser?.token]);

  const handleUpdate = (user) => {
    setSelectedUser(user);
    setUpdateFields({
      fullName: user.fullName,
      phone: user.phone,
      email: user.email,
      role: user.role,
      profilePic: null
    });
    setOpenDialog(true);
  };

  const handleDelete = async (userId) => {
    try {
      const response = await fetch(`http://localhost:3001/api/v1/user/${userId}`, {
        method: 'DELETE',
        headers: {
          "Authorization": `Bearer ${loginUser?.token}`
        }
      });
      if (response.ok) {
        setUserRows(userRows.filter(user => user._id !== userId));
        setSnack({ open: true, message: 'کاربر با موفقیت حذف شد', severity: 'success' });
      } else {
        throw new Error('Error deleting user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnack({ open: true, message: 'خطا در حذف کاربر', severity: 'error' });
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFields({ ...updateFields, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUpdateFields({ ...updateFields, profilePic: file });
  };

  const handleUpdateSubmit = async () => {
    const formData = new FormData();
    formData.append('fullName', updateFields.fullName);
    formData.append('phone', updateFields.phone);
    formData.append('email', updateFields.email);
    formData.append('role', updateFields.role);
    if (updateFields.profilePic) {
      formData.append('profilePic', updateFields.profilePic);
    }

    try {
      const response = await fetch(`http://localhost:3001/api/v1/user/${selectedUser._id}`, {
        method: 'PATCH',
        headers: {
          "Authorization": `Bearer ${loginUser?.token}`
        },
        body: formData
      });
      if (response.ok) {
        const updatedUser = await response.json();
        setUserRows(userRows.map(user => user._id === selectedUser._id ? updatedUser.data : user));
        setSnack({ open: true, message: 'کاربر با موفقیت بروزرسانی شد', severity: 'success' });
        handleDialogClose();
      } else {
        throw new Error('Error updating user');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setSnack({ open: true, message: 'خطا در بروزرسانی کاربر', severity: 'error' });
    }
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <>
      <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="flex-end">
        <PanelSidebar />
        <Stack
          sx={{
            width: isMobile ? "100%" : "80%",
            height: "100vh",
            marginTop: 10,
            marginRight: isMobile ? 0 : 35,
            padding: isMobile ? 2 : 0
          }}
        >
          <TableContainer component={Paper} sx={{ height: isMobile ? 'auto' : '80vh', overflowX: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>نام و نام خانوادگی</TableCell>
                  <TableCell>موبایل</TableCell>
                  <TableCell>ایمیل</TableCell>
                  <TableCell>نقش</TableCell>
                  <TableCell>عکس پروفایل</TableCell>
                  <TableCell>امضا</TableCell>
                  <TableCell>عملیات</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userRows && userRows.map(user => (
                  <TableRow key={user._id}>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>
                      {user.profilePic ? (
                        <img src={`http://localhost:3001${user.profilePic}`} alt="Profile Pic" style={{ width: 50, height: 50, borderRadius: '50%' }} />
                      ) : (
                        <Typography>عکسی یافت نشد</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {user.signature && (
                        <img src={user.signature} alt="User Signature" style={{ width: 50, height: 50 }} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => handleUpdate(user)}>ویرایش</Button>
                      <Button variant="contained" color="error" onClick={() => handleDelete(user._id)}>حذف</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>بروزرسانی کاربر</DialogTitle>
        <DialogContent>
          <DialogContentText>
            لطفا اطلاعات کاربر را بروزرسانی کنید.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="fullName"
            placeholder="نام و نام خانوادگی"
            type="text"
            fullWidth
            value={updateFields.fullName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="phone"
            placeholder="موبایل"
            type="text"
            fullWidth
            value={updateFields.phone}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            placeholder="ایمیل"
            type="email"
            fullWidth
            value={updateFields.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="role"
            placeholder="نقش"
            type="text"
            fullWidth
            value={updateFields.role}
            onChange={handleInputChange}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ marginTop: '1rem' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            لغو
          </Button>
          <Button onClick={handleUpdateSubmit} color="primary">
            بروزرسانی
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert onClose={() => setSnack({ ...snack, open: false })} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
}
