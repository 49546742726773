import React, { useEffect, useState ,useContext} from 'react';
import { useParams } from "react-router-dom";
import './update.css'
import PanelSidebar from '../PanelSlidebar';
import { AuthContext } from '../../../utils/AuthContext';
import { Navigate } from 'react-router-dom';
function ProjectUpdate() {
  const [projectData, setProjectData] = useState(); 
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext)
  useEffect(() => {
    fetch(`http://localhost:3001/api/v1/project`) 
      .then((res) => res.json())
      .then((data) => setProjectData(data.data)) 
      .catch((err) => console.log(err));
  }, [id]);

  const deleteItem = (id) => {
    fetch(`http://localhost:3001/api/v1/project/${id}`, { 
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        setProjectData(projectData.filter(project => project._id !== id)); 
      })
      .catch((err) => console.log(err));
      
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div  className='myslider-panel' >
        <h1> حذف کردن اطلاعات </h1>
        <PanelSidebar/>
      {projectData?.map(project => ( // Changed from sliderData to projectData
        <div className='slide'  key={project._id}> 
          <h3>{project.title}</h3>
          <img src={`http://localhost:3001${project.image}`} alt={project.title} /> 
          <p>{project.description}</p> 
          <button onClick={() => deleteItem(project._id)}>حذف</button> 
        </div>
      ))}
    </div>
  );
}

export default ProjectUpdate;
