// Navbar.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css'; // Import your CSS file for styling
import { Person2TwoTone } from '@mui/icons-material';
import TranslateButton from '../Translate/TranslateButton';
import logo1 from '../assest/log.png'
const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
       
        <Link to="/" className="navbar-logo">
          <img src={logo1} alt="" />

        </Link>
        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        
        <ul className={`nav-menu ${isOpen ? 'active' : ''}`}>
       
        <Link to="/contact" className="nav-links" onClick={toggleMenu}>
            {t('navbar.contact')}
           
            </Link>
         
          <li className="nav-item">
           
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={toggleMenu}>
            {t('navbar.about')}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/gallery" className="nav-links" onClick={toggleMenu}>
            {t('navbar.gallery')}
            </Link>
          </li>
          <li className="nav-item">
           
            <Link to="/" className="nav-links" onClick={toggleMenu}>
           
           {t('navbar.home')}
           </Link>
          </li>
          <li className="nav-item">
            <Link to="/login" className="nav-links" onClick={toggleMenu}>
              <Person2TwoTone  sx={{display:'flex', alignItems:'center',justifyContent:'center' ,textAlign:'center'}}/>
            </Link>
          </li>
          <TranslateButton  />
        </ul>
        
      </div>
    </nav>
  );
};

export default Nav;
