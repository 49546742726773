import React from 'react';
import './About.css';
import Nav from '../../Components/Nav';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook
import  pic from '../../assest/hi.jpg'
function About() {
  const { t } = useTranslation(); // Using the useTranslation hook to access translations

  return (
    <div>
      <Nav/>
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container bottomContainer">
          <div className="ultimateImg">
            <img className="mainImg" src={pic}/>
            <div className="purpleBox">
              <p className="purpleText"> {t('aboutpage.description2')}  </p> {/* Using translated text */}
            </div>
          </div>
          <div className="allText bottomText">
            <p className="text-blk headingText">{t('aboutpage.headingText')}</p> {/* Using translated text */}
            <p className="text-blk subHeadingText">{t('aboutpage.subHeadingText')}</p> {/* Using translated text */}
            <p className="text-blk description">{t('aboutpage.description')}</p> {/* Using translated text */}
            <Link to={'/project'} className="explore">{t('buttonn.explore')}</Link> {/* Using translated text */}
          </div>
        </div>
      </div>
     
      <Footer/>
    </div>
  );
}

export default About;
