import React, { useState } from 'react';
import './all.css';

const ResetPasswordRequest = ({ onNextStep }) => {
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [completed, setCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleResetRequest = async () => {
        const phonePattern = /^09\d{9}$/;

        // Validate phone number
        if (!phonePattern.test(phone)) {
            setMessage('شماره تلفن معتبر نیست.');
            console.log('Invalid phone number:', phone);
            return;
        }

        setIsLoading(true);
        try {
            console.log('Sending password reset request for phone:', phone);

            const response = await fetch('http://localhost:3001/api/v1/password-reset/request', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone }),
            });

            console.log('Response status:', response.status);

            const data = await response.json();
            console.log('Response data:', data);

            if (response.ok) {
                setMessage('لینک بازیابی به شماره تلفن شما ارسال شد.');
                setCompleted(true);
                onNextStep(true);
            } else {
                setMessage(data.error);
                console.error('Error response from server:', data.error);
            }
        } catch (error) {
            setMessage('خطا در ارتباط با سرور.');
            console.error('Error during fetch:', error);
        }
        setIsLoading(false);
    };

    return (
        <div className="mybox">
            <div className="container3">
                <h2>درخواست بازیابی رمز عبور</h2>
                <input
                    type="tel"
                    placeholder="شماره تلفن خود را وارد کنید"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <button onClick={handleResetRequest} disabled={isLoading}>
                    {isLoading ? 'در حال ارسال...' : 'ارسال لینک بازیابی'}
                </button>
                <p>{message}</p>
                {completed && <p>درخواست بازیابی انجام شد.</p>}
            </div>
        </div>
    );
}

export default ResetPasswordRequest;
