import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../utils/AuthContext.js';
import { Box, FormControlLabel, Button, Checkbox, Stack, TextField, Typography, InputAdornment, IconButton, Snackbar, Alert } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { colors, orange } from '@mui/material/colors'; // Ensure colors is imported

import './all.css';

function Login() {
  const { loginUser, setLoginUser } = useContext(AuthContext);
  
  const RegisterButton = styled(Link)`
    text-align: center;
    text-decoration: none;
    color: ${orange[600]}; /* Correctly use colors here */
  `;

  const [email, setEmail] = useState(''); // Email field (now used for numbers)
  const [password, setPassword] = useState('');
  const [successLogin, setSuccessLogin] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "error"
  });

  const handleLogin = async () => {
    // Simple validation for email and password fields
    if (!email || !password) {
      setSnack({ open: true, message: 'لطفا ایمیل و گذرواژه را وارد کنید', severity: 'error' });
      return;
    }

    fetch('http://localhost:3001/api/v1/user', {
      method: 'POST',
      headers: {
        "Content-type": "Application/json",
      },
      body: JSON.stringify({
        email,
        password,
      })
    }).then(res => res.json())
      .then(data => {
        if (data.status === "success") {
          setSuccessLogin(true);
          setLoginUser({ ...data?.data?.user });
          setLoginUser({
            ...loginUser,
            token: data.data.token,
            role: data.data.user.role,
            fullName: data.data.user.fullName,
            email: data.data.user.email,
            address: data.data.user.address,
            phone: data.data.user.phone,
            profilepic: data.data.profilePic
          });
          setSnack({ open: true, message: 'ورود موفقیت آمیز', severity: 'success' });
        } else {
          setSnack({ open: true, message: 'ایمیل یا گذرواژه اشتباه است', severity: 'error' });
        }
      })
      .catch(err => {
        console.log(err);
        setSnack({ open: true, message: 'خطایی رخ داده است', severity: 'error' });
      });
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    loginUser && localStorage.setItem("loginUser", JSON.stringify(loginUser));
    console.log(loginUser);
  }, [loginUser]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    const rememberedCredentials = JSON.parse(localStorage.getItem('rememberedCredentials'));
    if (rememberedCredentials && rememberedCredentials.email && rememberedCredentials.password) {
      setEmail(rememberedCredentials.email);
      setPassword(rememberedCredentials.password);
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    if (checked) {
      localStorage.setItem('rememberedCredentials', JSON.stringify({ email, password }));
    } else {
      localStorage.removeItem('rememberedCredentials');
    }
  }, [checked, email, password]);

  if (loginUser?.token && loginUser.role === 'employee') {
    return <Navigate to="/employee" />;
  } else if (loginUser?.token && (loginUser.role === 'admin' || loginUser.role === "superAdmin")) {
    return <Navigate to="/panel" />;
  }

  return (
    <>
      <div className='all-div'>
        <Stack alignItems="center" justifyContent="center" height="100vh" sx={{ direction: "ltr", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", boxShadow: '3' }}>
          <Box component="form" sx={{
            display: "flex", backgroundColor: 'whitesmoke', flexDirection: "column", boxShadow: '3', boxSizing: "border-box", border: "#ccc", borderRadius: "12px", '& legend': { textAlign: "left" }, '& input': { direction: 'ltr', textAlign: "center" },
            '@media (max-width:600px)': {
              maxWidth: '300px',
              '& .MuiTextField-root': {
                fontSize: '0.875rem'
              },
              '& .MuiButton-root': {
                fontSize: '0.875rem',
                padding: '6px 8px'
              }
            }
          }} gap={3} width="100%" maxWidth="450px" p={3} mt={3}>
            <Typography component="h1" sx={{ direction: "rtl", textAlign: "center", fontWeight: "600" }}>ورود به حساب کاربری</Typography>
            <TextField name="email" placeholder='نام کاربری' variant='outlined' required helperText="نام کاربری" sx={{
              '& .MuiFormHelperText-root': {
                direction: 'rtl',
                textAlign: 'right'
              }
            }}
              autoComplete="off"
              onChange={e => setEmail(e.target.value)}
              value={email}
              type="text" // Changed to text to prevent number spinner
              inputProps={{ pattern: "\\d*" }} // Regex pattern to allow only digits
            />
            <TextField name="password" placeholder="گذرواژه"
            helperText="گذرواژه"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={password}
            />
            <Box sx={{ textAlign: 'right', paddingRight: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", gap: '5' }}>
              <Typography variant='body1'>مرا به یاد داشته باش</Typography>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
              />
            </Box>
            {!successLogin && <Button variant="contained" disableElevation size='large' color='warning' sx={{ backgroundColor: "rgb(189,155,25)" }} onClick={handleLogin}>ورود و دریافت کد</Button>}
            <Button component={Link} to="/password" variant='outlined' size='large' sx={{ color: "rgb(189,155,25)", border: "rgb(189,155,25)" }}>فراموشی گذرواژه؟</Button>
          </Box>
        </Stack>
        <Snackbar open={snack.open} autoHideDuration={6000} onClose={() => setSnack({ ...snack, open: false })}>
          <Alert onClose={() => setSnack({ ...snack, open: false })} severity={snack.severity}>
            {snack.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default Login;
