

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Nav from '../../Components/Nav';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import './Project.css';
import pic2 from '../../assest/sakhtt.jpg'
import pic4 from '../../assest/sakhttt.jpg'
import pic3 from '../../assest/sakhtttt.jpg'
import pic5 from '../../assest/p.jpg'
import pic6 from '../../assest/pn.JPG'
import pic7 from '../../assest/ppp.jpg'
import pic8 from '../../assest/16.jpg'
import pic9 from '../../assest/17.jpg'

const Project = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/v1/project');
        const data = await response.json();
        if (response.ok) {
          setProjects(data.data);
        } else {
          console.error('Failed to fetch project data', data);
        }
      } catch (error) {
        console.error('Error fetching project data', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div>
      <Nav />
      <br />
      <div className='all-card'>
        <div className='ptitle'>
          <h1>{t('project.title')}</h1>
        </div>
        {/* {projects.map((project) => (
          <div className="card" key={project.id}>
            <img className="card-image" src={`http://localhost:3001${project.image}`} alt={project.title} />
            <div className="card-content">
              <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p>
            </div>
          </div>
        ))} */}
        
     

      <div className="card" >
            <img className="card-image" src={pic4} alt='project' />
            <div className="card-content">
              {/* <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p> */}
            </div>
          </div>
          <div className="card" >
            <img className="card-image" src={pic2} alt='project' />
            <div className="card-content">
              {/* <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p> */}
            </div>
          </div>
          <div className="card" >
            <img className="card-image" src={pic3} alt='project' />
            <div className="card-content">
              {/* <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p> */}
            </div>
          </div>
          <div className="card" >
            <img className="card-image" src={pic5} alt='project' />
            <div className="card-content">
              {/* <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p> */}
            </div>
          </div>
          <div className="card" >
            <img className="card-image" src={pic6} alt='project' />
            <div className="card-content">
              {/* <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p> */}
            </div>
          </div>
          <div className="card" >
            <img className="card-image" src={pic7} alt='project' />
            <div className="card-content">
              {/* <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p> */}
            </div>
          </div>
          <div className="card" >
            <img className="card-image" src={pic8} alt='project' />
            <div className="card-content">
              {/* <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p> */}
            </div>
          </div>
          <div className="card" >
            <img className="card-image" src={pic9} alt='project' />
            <div className="card-content">
              {/* <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p> */}
            </div>
          </div>
      <br />
      <Footer />
    </div>
    </div>
  );
};

export default Project;
