import React, { useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PanelSidebar from './PanelSlidebar';
import { AuthContext } from '../../utils/AuthContext';

const EmailForm = () => {
  const [emailData, setEmailData] = useState({ to: '', subject: '', text: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success'); // پیش فرض برای موفقیت
  const { loginUser } = useContext(AuthContext);

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const sendEmail = async () => {
    if (!emailData.to || !emailData.subject || !emailData.text) {
      setAlertMessage('لطفاً تمامی فیلدها را پر کنید');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }
  
    try {
      const res = await fetch('http://localhost:3001/api/v1/sendemail', {
        method: 'POST',
        headers: {
          'Content-type': 'Application/json',
          Authorization: `Bearer ${loginUser.token}`,
        },
        body: JSON.stringify(emailData),
      });
  
      const data = await res.json();
  
      if (res.status === 200) {
        setAlertMessage('ایمیل با موفقیت ارسال شد.');
        setAlertSeverity('success');
      } else {
        throw new Error('ناموفق');
      }
    } catch (err) {
      console.error(err);
      setAlertMessage('خطا در ارسال ایمیل');
      setAlertSeverity('error');
    } finally {
      setAlertOpen(true);
    }
  };

  return (
    !loginUser?.token ? (
      <Navigate to="/login" />
    ) : (
      loginUser.role !== 'admin' ? (
        <div className="slider-panel">
          <PanelSidebar />
          <div className="title-dashbored">
            <h1>ارسال ایمیل</h1>
            <h3>management@asiamehr.com</h3>
          </div>
          <input
            className='slider-input-text'
            type="email"
            placeholder="به"
            value={emailData.to}
            onChange={(e) => setEmailData({ ...emailData, to: e.target.value })}
          />
          <input
            className='slider-input-text'
            type="text"
            placeholder="موضوع"
            value={emailData.subject}
            onChange={(e) => setEmailData({ ...emailData, subject: e.target.value })}
          />
          <textarea
            className="slider-textarea"
            placeholder="پیام"
            value={emailData.text}
            onChange={(e) => setEmailData({ ...emailData, text: e.target.value })}
          ></textarea>

          <button onClick={sendEmail} className="slider-btn">
            ارسال ایمیل
          </button>
          <Link to='https://webmail.asiamehr.com/'>ورود به وب‌میل</Link>
          <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
            <MuiAlert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/" /> // انتقال سوپر ادمین به صفحه دیگر در صورت تلاش برای دسترسی به فرم ارسال ایمیل
      )
    )
  );
};

export default EmailForm;
