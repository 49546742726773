import React, { useState, useEffect } from 'react';
import './Main.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import pic2 from '../../assest/sakhtt.jpg'
import pic4 from '../../assest/sakhttt.jpg'
import pic3 from '../../assest/sakhtttt.jpg'
function MainGallery() {
  const { t } = useTranslation();
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/v1/cart');
        if (!response.ok) {
          throw new Error('Failed to fetch cart data');
        }
        const data = await response.json();
        setCartData(data.data); // Assuming your API response contains an array of cart data
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };

    fetchCartData();
  }, []);

  return (
    <div className="gallery">
      <div className='main-title'></div>
      {cartData.map((item, index) => (
        <div className="image-container" key={index}>
          <img src={`http://localhost:3001${item.image}`} alt={item.title} />
          <div className='image-text'>
            <h2>{item.title}</h2>
          </div>
          <div className="overlay">
            <div className="text">
              <div>
                <p>{item.description}</p>
                <Link to={'/project'} className="custom-link">{t('image.link')}</Link>
              </div>
            </div>
            <div className="social-icons"></div>
          </div>
        </div>
      ))}
      {/* You can keep the static images as well */}
      {/* 
      
      <div className="image-container">
        <img src={pic3} alt="Static Image 1" />
        <div className='image-text'>
          <h2>{t('image.title2')}</h2>
        </div>
        <div className="overlay">
          <div className="text">
            <div>
              <Link to={'/project'} className="custom-link">{t('image.link')}</Link>
            </div>
          </div>
          <div className="social-icons"></div>
        </div>
      </div>
      <div className="image-container">
        <img src={pic2} alt="Static Image 2" />
        <div className='image-text'>
          <h2>{t('image.title3')}</h2>
        </div>
        <div className="overlay">
          <div className="text">
            <div>
              <Link to={'/project'} className="custom-link">{t('image.link')}</Link>
            </div>
          </div>
          <div className="social-icons"></div>
        </div>
      </div> */}
      <div className="image-container">
  <img src={pic2} alt="Static Image 2" />
  <div className='image-text'>
    <h2>{t('progress_stage_5')}</h2>
  </div>
  <div className="overlay">
    <div className="text">
      <div>
        <Link to={'/project'} className="custom-link">{t('image.link')}</Link>
      </div>
    </div>
    <div className="social-icons"></div>
  </div>
</div>
<div className="image-container">
  <img src={pic3} alt="Static Image 2" />
  <div className='image-text'>
    <h2>{t('progress_stage_4')}</h2>
  </div>
  <div className="overlay">
    <div className="text">
      <div>
        <Link to={'/project'} className="custom-link">{t('image.link')}</Link>
      </div>
    </div>
    <div className="social-icons"></div>
  </div>
</div>


<div className="image-container">
  <img src={pic4} alt="Static Image 2" />
  <div className='image-text'>
    <h2>{t('progress_stage_6')}</h2>
  </div>
  <div className="overlay">
    <div className="text">
      <div>
        <Link to={'/project'} className="custom-link">{t('image.link')}</Link>
      </div>
    </div>
    <div className="social-icons"></div>
  </div>
</div>

    </div>
  );
}

export default MainGallery;
