import React from 'react'
import Employees from './Employe'
import EmployeeMine from './EmployeeMine'
import { IconButton, Stack, colors } from '@mui/material'
import { useContext } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../../utils/AuthContext'
import './Emploee.css'
function EmployeePanel() {
  const location = useLocation()
  const { loginUser, setLoginUser } = useContext(AuthContext)
  return (
    <div>
      
       <Stack >
       
          <Employees location={location}/>
        <Employees/>
         <EmployeeMine>
        {loginUser ? (
        <div className='user-info'  >
          <h1>خوش آمدید {loginUser.fullName}</h1>
         
          <p>ایمیل: {loginUser.email}</p>
         
          <p>نقش کاربری: {loginUser.role}</p>
          <p>شماره تماس : {loginUser.phone}</p>
          <p>ادرس : {loginUser.address}</p>
        </div>
      ) : (
        <p>شما وارد نشده‌اید.</p>
      )}
        </EmployeeMine>
        </Stack >
    </div>
  )
}

export default EmployeePanel
