import React, { useEffect, useState } from 'react';
import './gallery.css';
import pic from '../../assest/g.jpg'
import pic2 from '../../assest/g1.jpg'
import pic3 from '../../assest/g2.jpg'
import pic4 from '../../assest/p.jpg'
import pic5 from '../../assest/slider1.jpg'
import pic6 from '../../assest/ppp.jpg'
const GalleryInfo = () => {
  const [galleryItems, setGalleryItems] = useState([]);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/v1/gallerypage');
        const data = await response.json();
        if (response.ok) {
          setGalleryItems(data.data); // Assuming your API returns an array of gallery items in data.data
        } else {
          console.error('Failed to fetch gallery items', data);
        }
      } catch (error) {
        console.error('Error fetching gallery items:', error);
      }
    };

    fetchGalleryItems();
  }, []);

  return (
    <div className="masonry-container">
      {/* {galleryItems.map((item, index) => (
        <div key={index} className="panel">
          <div className="panel-wrapper">
            <div className="panel-overlay">
              <div className="panel-text">
                <div className="panel-title">{item.title}</div>
                <div className="panel-tags">
                
                  <span className="tags-list"></span>
                </div>
              </div>
              <img className="panel-gradient" alt="" />
              <img className="panel-vingette" alt="" />
            </div>
            <img className="panel-img" src={`http://localhost:3001${item.image}`} alt={item.gallery} />
          </div>
        </div>
      ))} */}
       
        <div className="gallery-grid">
      <div className="gallery-item">
        <img src={pic} alt="Gallery 1" className="gallery-image" />
      </div>
      <div className="gallery-item">
        <img src={pic2} alt="Gallery 2" className="gallery-image" />
      </div>
      <div className="gallery-item">
        <img src={pic3} alt="Gallery 3" className="gallery-image" />
      </div>
      <div className="gallery-item">
        <img src={pic4} alt="Gallery 3" className="gallery-image" />
      </div>
      <div className="gallery-item">
        <img src={pic5} alt="Gallery 3" className="gallery-image" />
      </div>
      <div className="gallery-item">
        <img src={pic6} alt="Gallery 3" className="gallery-image" />
      </div>
    </div>

    </div>
  );
};

export default GalleryInfo;
