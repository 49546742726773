   // LanguageContext.js
   import React, { createContext, useState } from 'react';
   const LanguageContext = createContext();
   const LanguageProvider = ({ children }) => {
       const [language, setLanguage] = useState('fa'); // Default language

       const changemyLanguage = (newLanguage) => {
           setLanguage(newLanguage);
       };

       return (
           <LanguageContext.Provider value={{ language, changemyLanguage }}>
               {children}
           </LanguageContext.Provider>
       );
   };

   export { LanguageContext, LanguageProvider };
   
