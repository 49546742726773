import React, { useState } from 'react';

import  ResetPasswordRequest from './ResetPassRequest'
import ResetPassword from './ResetPassword';
const Password = () => {
  const [step, setStep] = useState(1); // 1: reset request, 2: reset password

  const goToNextStep = () => {
    setStep(step + 1);
  };

  return (
    <div>
      {step === 1 && <ResetPasswordRequest onNextStep={goToNextStep} />}
      {step === 2 && <ResetPassword onNextStep={goToNextStep} />}
    </div>
  );
};

export default Password;
