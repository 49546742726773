import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './all.css';

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleResetPassword = async () => {
        setIsLoading(true);
        try {
            console.log('Resetting password with token:', token);

            const response = await fetch('http://localhost:3001/api/v1/password-reset/reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, password }),
            });

            const data = await response.json();
            console.log('Response data:', data);

            if (response.ok) {
                setMessage('رمز عبور با موفقیت تغییر یافت.');
            } else {
                setMessage(data.error);
                console.error('Error response from server:', data.error);
            }
        } catch (error) {
            setMessage('خطا در ارتباط با سرور.');
            console.error('Error during fetch:', error);
        }
        setIsLoading(false);
    };

    return (
        <div className="mybox">
            <div className="container3">
                <h2>بازنشانی رمز عبور</h2>
                <input
                    type="password"
                    placeholder="رمز عبور جدید خود را وارد کنید"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleResetPassword} disabled={isLoading}>
                    {isLoading ? 'در حال ارسال...' : 'تغییر رمز عبور'}
                </button>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default ResetPassword;
