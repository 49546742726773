import React, { useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Snackbar, Alert, Select, MenuItem, TextField } from '@mui/material';
import { AuthContext } from '../../utils/AuthContext';
import PanelSlidebar from './PanelSlidebar';

const languages = [
  { code: 'fa', label: 'فارسی', flag: 'ir' },
  { code: 'en', label: 'انگلیسی', flag: 'us' },
];

const SliderPanel = () => {
  const { loginUser } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [mytext, setMytext] = useState([]);
  const [selectedLang, setSelectedLang] = useState('');
  const [inputtext, setInputtext] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [file, setFile] = useState(null);
  const [imageURL, setImageURL] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAddTranslation = () => {
    setMytext([...mytext, { lang: selectedLang, inputtext }]);
    setSelectedLang('');
    setInputtext('');
  };

  const addSlider = async (e) => {
    e.preventDefault();

    if (!title || !file) {
      console.error('Title and image are required');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', file);
    formData.append('description', description);
    formData.append('mytext', JSON.stringify(mytext));

    try {
      const response = await fetch('http://localhost:3001/api/v1/slider', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${loginUser.token}`
        },
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Slider added successfully');
        setAlertMessage('افزودن محتوا موفقیت آمیز');
        setAlertSeverity('success');
        setAlertOpen(true);
        setImageURL(data.data.image);
      } else {
        console.log('Failed to add slider. Response:', data);
        setAlertMessage('افزودن محتوا ناموفق');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error adding slider:', error);
      setAlertMessage('خطا در افزودن محتوا');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div className='mypanel'>
      <form className='slider-panel'>
        <div>
          <h1>افزودن محتوا به اسلایدر</h1>
        </div>
        <PanelSlidebar className="panel-slidebar" />
        <input type="text" className="slider-input-text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="تیتر" required />
        <input type="file" className="slider-input-file" onChange={handleFileChange} placeholder="آدرس عکس" required />
        <textarea className="slider-textarea" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="توضیحات"></textarea>

        <div className="translation-row">
          <Select
            className="slider-select"
            value={selectedLang}
            onChange={(e) => setSelectedLang(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>انتخاب زبان</MenuItem>
            {languages.map(lang => (
              <MenuItem key={lang.code} value={lang.code}>
                <span className={`flag-icon flag-icon-${lang.flag}`}></span> {lang.label}
              </MenuItem>
            ))}
          </Select>
          <textarea
            className="slider-textfield"
            value={inputtext}
            onChange={(e) => setInputtext(e.target.value)}
            placeholder="متن ترجمه"
          />
        </div>
        <button type="button" className='add-translation-btn' onClick={handleAddTranslation}>افزودن ترجمه</button>

        <div className="translation-display">
          {mytext.map((translation, index) => (
            <div key={index}>
              <p>{languages.find(lang => lang.code === translation.lang)?.label}: {translation.inputtext}</p>
            </div>
          ))}
        </div>

        <button onClick={addSlider} className='slider-btn'>اضافه کردن محتوا</button>
        {imageURL && (
          <div>
            <h3>تصویر بارگذاری شده:</h3>
            <img src={`http://localhost:3001${imageURL}`} alt="Uploaded Slider" style={{ maxWidth: '100%' }} />
          </div>
        )}
        <Link to='delete'>حذف کردن محتوا</Link>
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </form>
    </div>
  );
};

export default SliderPanel;
