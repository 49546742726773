import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Carrousel.css';
import pic2 from '../../assest/hi.jpg'
import pic3 from '../../assest/hhi.jpg'
import pic4 from '../../assest/hii.jpg'
import pic5 from '../../assest/p.jpg'
import pic6 from '../../assest/pn.JPG'
import pic7 from '../../assest/ppp.jpg'
import { Pagination, Navigation } from 'swiper/modules';

export default function Carrousel() {
  const [swiperRef, setSwiperRef] = useState(null);
  const [galleryData, setGalleryData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/v1/gallery');
        const data = await response.json();
        if (response.ok) {
          setGalleryData(data.data);
        } else {
          console.error('Failed to fetch gallery data:', data);
        }
      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    };

    fetchGalleryData();
  }, []);

  return (
    <>
      <div className='title-text'>
        <h1>{t('carousel.title')}</h1>
      </div>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}  // Default to showing one slide
        centeredSlides={true}
        spaceBetween={20}
        pagination={{
          type: 'fraction',
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        breakpoints={{
          780: {
            slidesPerView: 3, // Show three slides on screens larger than 780px
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3, // Keep showing three slides on larger screens
            spaceBetween: 40,
          },
        }}
      >
        {galleryData.map((item) => (
          <SwiperSlide key={item.id}>
            <img src={`http://localhost:3001${item.image}`} alt={item.title} />
            <div className='c-text'>
              <h1>{item.title}</h1>
              <h3>{item.description}</h3>
            </div>
          </SwiperSlide>
        ))}
 <SwiperSlide >
            <img src={pic7} />
            <div className='c-text'>
              {/* <h1>{item.title}</h1>
              <h3>{item.description}</h3> */}
            </div>
          </SwiperSlide>
<SwiperSlide >
            <img src={pic2}  />
            <div className='c-text'>
              {/* <h1>{item.title}</h1>
              <h3>{item.description}</h3> */}
            </div>
          </SwiperSlide>
          <SwiperSlide >
            <img src={pic3} />
            <div className='c-text'>
              {/* <h1>{item.title}</h1>
              <h3>{item.description}</h3> */}
            </div>
          </SwiperSlide>
          <SwiperSlide >
            <img src={pic4} />
            <div className='c-text'>
              {/* <h1>{item.title}</h1>
              <h3>{item.description}</h3> */}
            </div>
          </SwiperSlide>
          <SwiperSlide >
            <img src={pic5} />
            <div className='c-text'>
              {/* <h1>{item.title}</h1>
              <h3>{item.description}</h3> */}
            </div>
          </SwiperSlide>
          <SwiperSlide >
            <img src={pic6} />
            <div className='c-text'>
              {/* <h1>{item.title}</h1>
              <h3>{item.description}</h3> */}
            </div>
          </SwiperSlide>
      </Swiper>
    </>
  );
}
