import React from 'react'
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';

export const CartDeta={
    
}
function MainCart() {
  const { t } = useTranslation();
  return (
    <div  className='main-cart'>
      <div className='main-container' >
         <div className='text-main'  >
             <h1> {t('companyResponsibility.title')}   </h1>
             <p>
             
{t('companyResponsibility.main-text')}


             </p>
            
         </div>
         <div className='icon-main' >
         
         <div className='myicons'>
        <ManageHistoryIcon sx={{ color: 'rgb(189,155,25)', fontSize: 60 }} />
        <h3>{t('manage')}</h3>
      </div>
      <div className='myicons'>
        <PublicIcon sx={{ color: 'rgb(189,155,25)', fontSize: 60 }} />
        <h3>{t('export')}</h3>
      </div>
      <div className='myicons'>
        <GroupsIcon sx={{ color: 'rgb(189,155,25)', fontSize: 60 }} />
        <h3>{t('professional_team')}</h3>
      </div>
      <div className='myicons'>
        <AnalyticsOutlinedIcon sx={{ color: 'rgb(189,155,25)', fontSize: 60 }} />
        <h3>{t('analysis')}</h3>
      </div>
      <div className='myicons'>
        <EmojiObjectsOutlinedIcon sx={{ color: 'rgb(189,155,25)', fontSize: 60 }} />
        <h3>{t('ideation')}</h3>
      </div>
      <div className='myicons'>
        <EngineeringOutlinedIcon sx={{ color: 'rgb(189,155,25)', fontSize: 60 }} />
        <h3>{t('engineering_team')}</h3>
      </div>
         </div>
      </div>
    </div>
  )
}

export default MainCart
