import React, { useContext, useState, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Box, Button, Snackbar, Stack, TextField, Typography, Alert, MenuItem, FormControl, Select, Grid
} from '@mui/material';
import { AuthContext } from '../../utils/AuthContext';
import PanelSidebar from './PanelSlidebar';
import SignatureCanvas from 'react-signature-canvas';

export default function UserRegister() {
  const { loginUser } = useContext(AuthContext);
  const [fields, setFields] = useState({
    fullName: '',
    email: '',
    phone: '',
    password: '',
    address: '',
    profilePic: '',
    role: 'employee' // Default role
  });
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    severity: 'error'
  });
  const sigCanvas = useRef({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFields({ ...fields, profilePic: file });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('fullName', fields.fullName);
    formData.append('email', fields.email);
    formData.append('phone', fields.phone);
    formData.append('password', fields.password);
    formData.append('address', fields.address);
    formData.append('file', fields.profilePic);
    formData.append('role', fields.role);
    formData.append('signature', sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));

    try {
      const response = await fetch('http://localhost:3001/api/v1/user/register', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        setSnack({ open: true, message: 'حساب کاربری شما ایجاد شد! لطفا وارد حساب خود شوید.', severity: 'success' });
      } else {
        const errorData = await response.json();
        let errorMessage = 'مشکلی در ایجاد حساب کاربری پیش آمده است.';
        if (errorData.message.includes('email')) {
          errorMessage = 'این کد‌پرسنلی قبلاً ثبت شده است.';
        } else if (errorData.message.includes('phone')) {
          errorMessage = 'این شماره تلفن قبلاً ثبت شده است.';
        } else if (errorData.message.includes('fullName')) {
          errorMessage = 'این نام کامل قبلاً ثبت شده است.';
        }
        setSnack({ open: true, message: errorMessage, severity: 'error' });
      }
    } catch (error) {
      setSnack({ open: true, message: 'مشکلی در ایجاد حساب کاربری پیش آمده است.', severity: 'error' });
    }
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div>
      <PanelSidebar />
      <Stack alignItems="center" justifyContent="center" >
        <Box 
          component="form" 
          sx={{ 
            width: '100%', 
            maxWidth: '500px', 
            p: 3, 
            bgcolor: 'background.paper', 
            borderRadius: 1,
            textAlign: 'right',
            boxShadow: 3
          }} 
          onSubmit={handleRegister}
        >
          <Typography component="h1" variant="h5" align="center" gutterBottom>ثبت نام</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField 
                name="fullName" 
                variant="outlined" 
                onChange={handleChange} 
                placeholder="نام و نام خانوادگی" 
                fullWidth 
                margin="normal" 
                required 
                inputProps={{ style: { textAlign: 'right' } }} 
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: '0.875rem',
                    height: '56px',
                  },
                  '@media (max-width:600px)': {
                    '& .MuiInputBase-root': {
                      fontSize: '0.75rem',
                      height: '40px',
                      padding: '10px 14px'
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="email" 
                variant="outlined" 
                onChange={handleChange} 
                placeholder="کد‌پرسنلی"
                fullWidth 
                margin="normal" 
                required 
                type='tel'
                inputProps={{ style: { textAlign: 'right' } }} 
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: '0.875rem',
                    height: '56px',
                  },
                  '@media (max-width:600px)': {
                    '& .MuiInputBase-root': {
                      fontSize: '0.75rem',
                      height: '40px',
                      padding: '10px 14px'
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="phone" 
                variant="outlined" 
                onChange={handleChange} 
                placeholder="موبایل" 
                fullWidth 
                margin="normal" 
                required 
                inputProps={{ style: { textAlign: 'right' } }} 
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: '0.875rem',
                    height: '56px',
                  },
                  '@media (max-width:600px)': {
                    '& .MuiInputBase-root': {
                      fontSize: '0.75rem',
                      height: '40px',
                      padding: '10px 14px'
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="password" 
                type="password" 
                variant="outlined" 
                onChange={handleChange} 
                placeholder="حداقل تعداد کاراکترهای رمز عبور ۸ کاراکتر است" 
                fullWidth 
                margin="normal" 
                required 
                inputProps={{ style: { textAlign: 'right' } }} 
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: '0.875rem',
                    height: '56px',
                  },
                  '@media (max-width:600px)': {
                    '& .MuiInputBase-root': {
                      fontSize: '0.75rem',
                      height: '40px',
                      padding: '10px 14px'
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                name="address" 
                variant="outlined" 
                onChange={handleChange} 
                placeholder="آدرس" 
                fullWidth 
                margin="normal" 
                inputProps={{ style: { textAlign: 'right' } }} 
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: '0.875rem',
                    height: '56px',
                  },
                  '@media (max-width:600px)': {
                    '& .MuiInputBase-root': {
                      fontSize: '0.75rem',
                      height: '40px',
                      padding: '10px 14px'
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <Select
                  name="role"
                  value={fields.role}
                  onChange={handleChange}
                  variant="outlined"
                  displayEmpty
                  inputProps={{ style: { textAlign: 'right' } }}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: '0.875rem',
                      height: '56px',
                    },
                    '@media (max-width:600px)': {
                      '& .MuiInputBase-root': {
                        fontSize: '0.75rem',
                        height: '40px',
                        padding: '10px 14px'
                      }
                    }
                  }}
                >
                  <MenuItem value="employee">کارمند</MenuItem>
                  <MenuItem value="admin">مدیر</MenuItem>
                  <MenuItem value="superAdmin">سوپرمدیر</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <input 
                type="file" 
                onChange={handleFileChange} 
                required 
                style={{ 
                  marginTop: '16px', 
                  marginBottom: '16px', 
                  display: 'block', 
                  textAlign: 'right' 
                }} 
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>امضای دیجیتال</Typography>
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
                sx={{
                  '& .sigCanvas': {
                    height: '200px',
                  },
                  '@media (max-width:600px)': {
                    '& .sigCanvas': {
                      height: '150px',
                    }
                  }
                }}
              />
              <Button 
                variant="outlined" 
                onClick={clearSignature}
                fullWidth
                sx={{ mt: 1, mb: 2 }}
              >
                پاک کردن امضا
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                disableElevation 
                fullWidth
                sx={{ mt: 2 }}
              >
                ثبت نام
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Snackbar
          open={snack.open}
          autoHideDuration={6000}
          onClose={() => setSnack({ ...snack, open: false })}
        >
          <Alert onClose={() => setSnack({ ...snack, open: false })} severity={snack.severity}>
            {snack.message}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}
