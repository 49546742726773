import React, { useContext } from 'react'
// import { AuthContext } from '../../utils/AuthContext'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../../utils/AuthContext'
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material'
import PanelSidebar from './PanelSlidebar'
import { IconButton, Stack, colors } from '@mui/material'
import PanelMain from './PanelMain'
import './panel.css'
import './panel.css'
 function   Panel() {
    const location = useLocation()
    const { loginUser, setLoginUser } = useContext(AuthContext)
 
  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div   className='mypanel'>
        <Stack >
        
          <PanelSidebar location={location}/>
         
         <PanelMain>
        {loginUser ? (
        <div  className='user-info'>
          <h1>خوش آمدید {loginUser.fullName}</h1>
         
          <p>ایمیل: {loginUser.email}</p>
          <p>نقش کاربر: {loginUser.role}</p>
          <p>شماره تماس : {loginUser.phone}</p>
          <p>ادرس:{loginUser.address}</p>
          <img src={`http://localhost:3001${loginUser.profilePic}`} alt="profile" />
        </div>
      ) : (
        <p>شما وارد نشده‌اید.</p>
      )}
         </PanelMain>
        </Stack >
    </div>
  )
}

export default Panel
