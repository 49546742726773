import React from 'react'
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import ElectricRickshawOutlinedIcon from '@mui/icons-material/ElectricRickshawOutlined';
import FilterFramesOutlinedIcon from '@mui/icons-material/FilterFramesOutlined';


import { brown } from '@mui/material/colors';
import {Link} from 'react-router-dom'
import hoome from '../../assest/MEHR2COPY.png'
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
function Main() {
  const { t } = useTranslation();
  return (
   <>
  
       
       <div class="containerr">
         <div  className='main-pic'><img src={hoome} alt="" /></div>
        <div  className='main-text'>  <div> <h1>  {t('about')}</h1> </div> <div> <p> {t('companyMessage')}</p></div> 
       
        <Link to={'/about'} sx={{backgroundColor:'#FFD700 ' ,gap:'10' }} >{t('button.more')} </Link>
        <div>
        
       </div>
        </div>
      

       </div>
      
      
     </>
  
   
  )
}

export default Main
